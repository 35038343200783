import React from 'react'
import { navigate } from '@reach/router'
import { setUser, isLoggedIn } from '../../utils/auth'
import Error from './../Error'
import { Auth } from 'aws-amplify'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
} from 'shards-react'
import SEO from './../SEO'
import './loginStyles.less'

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    error: ``,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  login = async () => {
    const { username, password } = this.state
    try {
      await Auth.signIn(username, password)
      const user = await Auth.currentAuthenticatedUser()
      const userInfo = {
        ...user.attributes,
        username: user.username,
      }
      setUser(userInfo)
      navigate('/app/bookings')
    } catch (err) {
      this.setState({ error: err })
      console.log('error...: ', err)
    }
  }

  render() {
    if (isLoggedIn()) navigate('/app/bookings')
    return (
      <div>
        <div className="bg" />
        <Container
          fluid
          className="main-content-container h-100 px-4 container-fluid"
        >
          <SEO title="Access Your Account" />
          <Row>
            <Col className="uth-form mx-auto my-auto col-md-5 col-lg-3">
              <Card className="auth-form">
                <CardBody>
                  <Row>
                    <Col>
                      <img
                        id="main-logo"
                        className="auth-form__logo d-table mx-auto mb-3"
                        style={{ maxWidth: '300px', width: '100%' }}
                        src={require('../../images/logo.svg')}
                        alt="SeaDream"
                      />
                      <h5 className="auth-form__title text-center mb-4">
                        Access Your Account
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" className="form-group">
                      {this.state.error && (
                        <Error errorMessage={this.state.error} />
                      )}
                      <FormGroup>
                        <label htmlFor="feFirstName">Username</label>
                        <FormInput
                          onChange={this.handleUpdate}
                          placeholder="Username"
                          name="username"
                          value={this.state.username}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="feFirstName">Password</label>
                        <FormInput
                          onChange={this.handleUpdate}
                          placeholder="Password"
                          name="password"
                          value={this.state.password}
                          type="password"
                        />
                      </FormGroup>
                      <Button
                        className="d-table mx-auto btn btn-accent btn-pill"
                        theme="accent"
                        onClick={this.login}
                      >
                        Access Account
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Login
